import React from 'react';
import styled from 'styled-components';

import StyledLeftSided from '@/components/Sections/LeftSided';

interface BankLeftSidedProps {
  className?: string;
  heading: string;
  subsections: {
    heading?: string;
    text: string
  }[],
  backgroundImage: string;
  retinaBackgroundImage?: string;
  id?: string;
  scrollRefId?: string;
}

const BankLeftSection = ({ className, ...rest }: BankLeftSidedProps): JSX.Element => (
  <StyledLeftSided
    className={className}
    {...rest}
  />
);

const StyledBankLeftSided = styled(BankLeftSection)`
  margin-top: 140px;
  margin-bottom: 60px;

  @media (max-width: 991px) {
    margin-top: 0;
  }

  @media (max-width: 767px) {
    margin-bottom: 0;
  }

  > .section-container {
    background-size: cover;
    background-position-x: right;
    background-position-y: center;

    @media (max-width: 1679px) {
      background-position-x: -100px;
    }

    @media (max-width: 1199px) {
      background-position-x: -190px;
    }

    @media (max-width: 991px) {
      background-position-x: 55%;
    }
  }

  .image {
    background-position: right center;
  }

  .content {
    padding: 150px 0 160px 0;
    max-width: 508px;

    @media (max-width: 1399px) {
      padding: 117px 0 125px 0;
      max-width: 444px;
    }

    @media (max-width: 1199px) {
      padding: 108px 0 115px 0;
    }

    @media (max-width: 991px) {
      padding: 94px 0 100px 0;
      max-width: 48%;
    }

    @media (max-width: 767px) {
      padding-top: 24px;
      padding-bottom: 80px;
      padding-left: 36px;
      padding-right: 36px;
      max-width: unset;
    }

    p {
      margin-bottom: 64px;

      @media (max-width: 1399px) {
        margin-bottom: 56px;
      }

      @media (max-width: 767px) {
        margin-bottom: 40px;
      }
    }
  }
`;

export default StyledBankLeftSided;
