import { PageProps } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { ForBank, ForBankRetina } from '@/assets/images';
import { BanksBanner, WhyFintelli } from '@/components';
import StyledBankLeftSided from '@/components/Banks/LeftSided';
import StyledFooter from '@/components/Footer';
import StyledNavigationBar from '@/components/NavigationBar';
import StyledContactForm from '@/components/Sections/ContactForm';
import Layout from '@/styles/index';

const Banks = ({ location }: PageProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{ t('common:page-titles.banks') }</title>
      </Helmet>
      <Layout>
        <div
          className="dummy"
          id="banner"
        />
        <StyledNavigationBar
          className="navbar"
          darkMenuIcon
          initialValue="FOR_BANK"
        />
        <BanksBanner />
        <StyledBankLeftSided
          backgroundImage={ForBank}
          className="for-bank"
          heading={t('banks:middle-section.title')}
          retinaBackgroundImage={ForBankRetina}
          scrollRefId="banks-left-sided"
          subsections={[
            {
              heading: t('banks:middle-section.sections.first.title'),
              text: t('banks:middle-section.sections.first.content'),
            },
            {
              heading: t('banks:middle-section.sections.second.title'),
              text: t('banks:middle-section.sections.second.content'),
            },
            {
              heading: t('banks:middle-section.sections.third.title'),
              text: t('banks:middle-section.sections.third.content'),
            },
          ]}
        />
        <WhyFintelli />
        <StyledContactForm
          id="contact"
          location={location}
        />
        <StyledFooter />
      </Layout>
    </>
  );
};

export default Banks;
